.home h1 {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 38px;
  text-align: center;
  line-height: 1.15;
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
}
