@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");
@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");

.App {
  text-align: center;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

body {
  background-color: #212121;
  color: #fff;
  font-family: "Muli", sans-serif;
}
