.bubbleUI {
  /* position: relative; */
  /* background-color: rgba(228, 25, 25, 0) !important; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  margin: 0 0;
  /* background-color: white !important; */
  overflow: hidden !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

._1Lxpd {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
._2MD0k::-webkit-scrollbar {
  display: none;
}

._376IX {
  position: relative;
  height: 50%;
  width: 100%;
}

._3bAl3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 50%;
  padding-right: 50%;
  box-sizing: content-box;
}

._1iLpS {
  display: flex;
  flex-direction: row;
}

._2gu6r {
  display: flex;
  justify-content: center;
  align-items: center;
}

._3cleF {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
}

._3cleF > * {
  width: 100% !important;
  height: 100%;
}

._2SNHQ {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

._2pju2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.12);
}

._2MD0k {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: scroll !important;
  scroll-padding: 100px 0 0 100px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
