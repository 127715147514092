.bubble {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s ease;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
/* 
.bubble:hover {
  opacity: 0.5;
} */

.img{
  width: 55%;
  height: 55%;
  object-fit: contain;
}